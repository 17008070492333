import type { UserVariant, ResExposure } from '~/types/amplitude/types'

export const useExperimentExposure = (flagKey: string) => {
  const { $httpCookies } = useNuxtApp()
  const experimentCookie = $httpCookies.get('_dcid') || $httpCookies.get('_amp')
  const userVariant = useState<UserVariant>('user-variant')

  if (!userVariant.value && process.env.NODE_ENV === 'development') {
    console.error('No user variant found')
  }

  const queryParams = {
    flagKey,
    deviceId: experimentCookie,
    variant: userVariant.value[flagKey],
  }
  const url = `/api/expose?${new URLSearchParams(queryParams).toString()}`

  return useFetch<ResExposure>(url)
}
